.headerWrapper {
  display: flex;
  flex-direction: column;
  height: 15%;
  background-color: white;
  border-bottom: 2px solid #e6e6e6;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60%;
    padding: 0.5rem 0 0 0;

    .logoTitle {
      display: flex;
      flex-direction: row;
      width: 30%;
      height: 100%;
      align-items: center;
      cursor: pointer;
      padding-left: 8rem;

      .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        margin-left: 1rem;
      }
    }

    .searchBox {
      width: 40%;
      padding: 1rem 1rem 0 1rem;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      .searchInput {
        width: 60%;
        height: 2rem;
        background-color: #f2f2f2;
        border-radius: 8px;
        border: none;
        padding: 0.25rem 2rem;
      }
      .userPincode {
        color: #20695c;
        font-weight: 600;
      }
    }

    .otherDetails {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 30%;
      .navBtn {
        display: flex;
        align-items: center;
        flex-direction: column;
        border: none;
        background-color: white;
        cursor: pointer;
        padding: 1rem;
        border: 4px solid transparent;
        .topIcon {
          position: absolute;
          display: flex;
          align-items: center;
          color: white;
          background-color: #20695c;
          border-radius: 50%;
          height: 1rem;
          margin-right: -1.5rem;
          font-size: 13px;
          width: 1rem;
          justify-content: center;
        }

        .btnText {
          margin-top: 2px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .activeClassNav {
        border-top: 4px solid #20695c;
        color: #20695c;
      }
      .navBtn:hover {
        border-top: 4px solid #20695c;
      }
    }
  }
  .otherPages {
    .hamBurger {
      display: none;
    }
    display: flex;
    align-items: flex-start;
    padding: 0 0 1rem 0;
    width: 100%;
    height: 30%;
    .otherPagesButton:first-child {
      margin-left: 8rem;
    }
    .otherPagesButton {
      margin: 0rem 1rem 0rem 1rem;
      border: none;
      background-color: white;
      padding: 4px 12px;
      border-radius: 50px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      .btnText {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        text-decoration: none;
      }
    }
    .otherPagesButton:hover {
      background-color: #e6e6e6;
    }
  }
}
@media all and (min-width: 500px) and (max-width: 900px) {
  .headerWrapper {
    display: flex;
    flex-direction: column;
    height: 15%;
    background-color: white;
    border: 2px solid #e6e6e6;
    align-items: center;
    padding: 0 3rem;

    .content {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 60%;
      padding: 0.5rem 0 0 0;

      .logoTitle {
        display: flex;
        flex-direction: row;
        width: 40%;
        height: 100%;
        align-items: center;
        cursor: pointer;

        .title {
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
          margin-left: 1rem;
        }
      }

      .searchBox {
        width: 30%;
        padding: 1rem 1rem 0 1rem;
        height: 100%;
        display: flex;
        align-items: center;
        .searchInput {
          width: 60%;
          height: 2rem;
          background-color: #f2f2f2;
          border-radius: 8px;
          border: none;
          padding: 0.25rem 2rem;
        }
      }

      .otherDetails {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 30%;
        .navBtn {
          display: flex;
          align-items: center;
          flex-direction: column;
          border: none;
          background-color: white;
          cursor: pointer;
          padding: 0.5rem;
          border: 4px solid transparent;

          .btnText {
            margin-top: 2px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }
        .navBtn:hover {
          border-top: 4px solid #20695c;
        }
      }
    }
    .otherPages {
      .hamBurger {
        display: none;
      }
      display: flex;
      padding: 0 1rem 1rem 1rem;
      width: 100%;
      height: 30%;
      align-items: center;
      justify-content: center;
      .otherPagesButton {
        margin: 0rem 1rem 0rem 1rem;
        border: none;
        background-color: white;
        padding: 4px 12px;
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        .btnText {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          text-decoration: none;
        }
      }
      .otherPagesButton:hover {
        background-color: #e6e6e6;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .headerWrapper {
    display: flex;
    flex-direction: row;
    height: 15%;
    background-color: white;
    border: 2px solid #e6e6e6;
    padding: 0 0.5rem;
    align-items: flex-start;
    .content {
      display: flex;
      flex-direction: row;
      width: 90%;
      height: 60%;
      padding: 0.5rem 0 0 0;
      align-items: flex-start;

      .logoTitle {
        display: flex;
        flex-direction: row;
        width: 70%;
        height: 100%;
        align-items: center;
        flex-wrap: nowrap;
        cursor: pointer;
        padding: 0;

        .title {
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
          margin-left: 0.5rem;
          text-wrap: nowrap;
        }
      }

      .searchBox {
        display: none;
      }

      .otherDetails {
        display: none;
      }
    }
    .otherPages {
      width: 5%;
      .hamBurger {
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        margin-top: 1.45rem;
        margin-right: 0.25rem;
      }
      .otherPagesButton {
        display: none;
      }
    }
  }
}
