.footerWrapper {
  display: flex;
  justify-content: center;
  padding: 1rem;
  .footerBox {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 1rem;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 2rem;

    .footerItem {
      width: 100%;
      display: flex;
justify-content: center;
      align-items: center;
      margin-top: 2rem;
      .footerTextBox {
        display: flex;
        flex-direction: column;
        .footerHeading {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;

          margin: 0 !important;
        }
        .footerText {
          color: #4d4d4d;
          font-size: 14px;
          line-height: 18px;
          margin: 0 !important;
        }
      }
      .footerImg {
        height: 3rem;
        width: 3rem;
        margin-right: 1rem;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .footerWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 !important;
    align-items: center;
    .footerBox {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 2rem;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 1rem;
      .footerItem {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        .footerTextBox {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .footerHeading {
            padding-bottom: 4px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            margin: 0 !important;
          }
          .footerText {
            color: #4d4d4d;
            font-size: 12px;
            line-height: 16px;
            margin: 0 !important;
          }
        }
        .footerImg {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }
}
