html,
body {
  padding: 0;
  margin: 0;
  font-family: 'GalanoGrotesque', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.font-bold {
  font-weight: 500;
}
.f-16 {
  font-size: 18px;;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: 1rem;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.h-3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.mt-0-5 {
  margin-top: 0.5rem !important;
}

.img-3-3 {
  height: 3rem;
  width: 3rem;
}

.w-100 {
  width: 100%;
}

.display-none {
  display: none;
}
