.paymentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  .fontAwesome {
    background-color: rgb(243, 34, 34);
  }
  .mobileHeader {
    display: none;
  }
  .mobileCheckout {
    display: none;
  }
  .leftSection {
    width: 65%;
    height: 500px;
    align-items: end;
    overflow: auto;
    margin-left: 8rem;
    padding: 1rem 3rem;
    margin-bottom: 5rem;
    .paymentInfo {
      display: flex;
      flex-direction: row;
      width: 95%;
      .paymentOptions {
        display: flex;
        flex-direction: column;
        width: 35%;
        border-right: 0.5px solid #e4e4e4;
        height: 40vh;
        .paymentOption {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          cursor: pointer;
          margin-top: 1rem;
          transition: none;
          padding: 12px 12px 12px 0;
        }
        .paymentActive {
          color: #206a5d;
          background-image: linear-gradient(
            to left,
            rgba(32, 106, 93, 0.08),
            rgba(32, 106, 93, 0)
          );
          border-right: 4px solid #206a5d;
          fill: #206a5d;
        }
      }
      .paymentSpecifics {
        display: flex;
        flex-direction: column;
        width: 65%;
        height: 40vh;
        align-items: center;
        .desktopHidden {
          display: none !important;
        }
        .paymentBox {
          display: flex;
          flex-direction: column;
          border: 0.5px solid #e4e4e4;
          width: 98%;
          border-radius: 8px;
          margin-left: 3rem;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0.5rem 0.75rem;
          .desktopHidden {
            display: none;
          }
          .desktopHiddenRadio {
            display: none;
          }
          .paymentHeader {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }
          .paymentContent {
            font-size: 14px;
            line-height: 20px;
            color: #808080;
            margin-bottom: 2.5rem;
          }
        }
      }
    }

    .billingInformation {
      width: 95%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 20px;
      line-height: 28px;
      padding: 1rem 0;
      margin-bottom: 1rem;
      .cartInfo {
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
  .rightSection {
    display: flex;
    flex-direction: column;
    width: 35%;
    margin-top: 1.5rem;
    align-items: flex-start;
    height: 500px;
  }

  .checkoutWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: white;

    .leftSection::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background-color: transparent;
    }
  }
}

@media all and (max-width: 500px) {
  .paymentWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .mobileHeader {
      display: flex;
      flex-direction: row;
      position: sticky;
      position: -webkit-sticky;
      width: 100%;
      padding: 1rem;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid #e6e6e6;
      height: 4rem;
      top: 0;
      background-color: white;

      .stepInfo {
        font-size: 14px;
        line-height: 20px;
        color: #808080;
      }
    }

    .mobileCheckout {
      display: flex;
      width: 100%;
      bottom: 4em;
      position: sticky;
      flex-direction: row;
      padding: 0.75rem 0;
      justify-content: space-between;
      background-color: white;
      border-top: 0.5px solid #e7e7e7;

      border-top: 0.5px bottom #e7e7e7;
      height: 5rem;
      .mobileLeft {
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;
        justify-content: center;
        .price {
          font-size: 20px;
          line-height: 29px;
        }
        .viewPriceDetails {
          font-size: 12px;
          line-height: 20px;
          color: #206a5d;
          text-decoration: underline;
          font-weight: 500;
        }
      }
      .mobileRight {
        display: flex;
        width: 50%;
        align-items: center;
      }
    }

    .checkoutWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: white;
      align-items: center;

      .leftSection::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
        background-color: transparent;
      }

      .leftSection {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        margin: 0 !important;
        margin-top: 1.5rem;
        overflow: auto;
        padding: 0 !important;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;

        .paymentInfo {
          display: flex;
          flex-direction: row;
          width: 95%;
          .paymentOptions {
            display: none;
            .paymentOption {
              display: none;
            }
          }
          .paymentSpecifics {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: space-around;
            .desktopHidden {
              display: flex !important;
            }
            .paymentBox {
              display: flex;
              flex-direction: row;
              border: 0.5px solid #e4e4e4;
              width: 98%;
              border-radius: 8px;
              margin: 0 !important;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 0.5rem 0.75rem;
              .desktopHiddenRadio {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #206a5d;
                height: 5rem;
                padding: 0 0.25rem;
              }
              input[type='radio'] {
                accent-color: #206a5d;
              }
              .desktopHidden {
                display: flex;
                padding-right: 0.25rem;
              }
              .payCardContent {
                .paymentHeader {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 22px;
                }
                .paymentContent {
                  font-size: 12px;
                  line-height: 20px;
                  color: #808080;
                  margin-bottom: 2rem;
                }
                .mobileHidden {
                  display: none;
                }
              }
            }
          }
        }
        .billingInformation {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 14px;
          line-height: 20px;
          padding: 0.5rem;
          background-color: #f5f5f5;
          height: 3rem;
          align-items: center;

          .cartInfo {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }

      .rightSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1.5rem;
        height: 260px;
        padding: 0.25rem;

        .cuponsBox {
          display: none;
          margin-top: 1rem;
          width: 80%;
          height: 4rem;
          border: solid 1px #e7e7e7;
          border-radius: 8px;
        }
      }
    }
  }
}
