.otherPagesButton {
  margin: 0rem 1rem 0rem 1rem;
  border: none;
  background-color: white;
  padding: 4px 12px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 30%;
  .btnText {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-decoration: none;
  }
}
.otherPagesButton:hover {
  background-color: #e6e6e6;
}



@media all and (max-width: 500px) {
    .otherPagesButton {
        margin: 0rem 0.5rem 0rem 0.5rem;
        border: none;
        background-color: white;
        padding: 2px 6px;
        border-radius: 25px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        width: 80%;
        .btnText {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          text-decoration: none;
        }
      }
      .otherPagesButton:hover {
        background-color: #e6e6e6;
      }
}
