.cardWrapper {
  border: solid 1px #e6e6e6;
  width: 27%;
  height: 10rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  margin: 1rem;
  cursor: pointer;

  .cardContent {
    height: 7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    padding: 0.5rem;
    .descAndTitle {
      display: flex;
      flex-direction: column;
      width: 100%;
      .title {
        color: #1a181e;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        width: 80%;
        word-wrap: break-word;
      }
      .title:hover {
        text-decoration: underline;
      }
      .shortDesc {
        text-transform: lowercase;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .itemImage {
      width: 6rem;
      height: 6rem;
      border: solid 1px #e6e6e6;
      box-shadow: none;
      border-radius: 8px;
    }
  }
  .cardFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    height: 3rem;
    padding: 0.5rem;
    .buttonsContainer {
      display: flex;
      width: 25%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      .addsubsButton {
        display: flex;
        align-items: center;
        color: #7fb33f;
        height: 1.5rem !important;
        width: 2rem !important;
        border: none;
        margin: 0 0.5rem;
        justify-content: center;
      }
      .addsubsButton:hover {
        background-color: #e6e6e6;
      }
    }

    .pricingInformation {
      display: flex;
      flex-direction: row;
      align-items: center;
      .price {
        margin-right: 6px;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
      }
      .strikedOutPrice {
        text-decoration: line-through;
        color: #858585;
        margin-right: 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      .percentageOffer {
        color: white;
        background-color: #7fb33f;
        padding-left: 0.35rem;
        padding-right: 0.35rem;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        border-radius: 4px;
      }
    }
  }
}
.cardWrapper:hover {
  background-color: #fafafa;
}

@media all and (min-width: 500px) and (max-width: 900px) {
  .cardWrapper {
    border: none;
    width: 45%;
    height: 9rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    margin: 0.5rem;
    cursor: pointer;
    border: solid 1px #e6e6e6;

    .cardContent {
      height: 5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      padding: 0.25rem;
      .descAndTitle {
        display: flex;
        flex-direction: column;
        .title {
          color: #1a181e;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        .title:hover {
          text-decoration: underline;
        }
        .shortDesc {
          text-transform: lowercase;
          margin-top: 8px;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }
      }
      .itemImage {
        width: 5rem;
        height: 5rem;
        border: solid 1px #e6e6e6;
        box-shadow: none;
        border-radius: 4px;
      }
    }
    .cardFooter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      height: 2rem;
      padding: 0.5rem;

      .pricingInformation {
        display: flex;
        flex-direction: row;
        align-items: center;
        .price {
          margin-right: 3px;
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
        }
        .strikedOutPrice {
          text-decoration: line-through;
          color: #858585;
          margin-right: 6px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        .percentageOffer {
          color: white;
          background-color: #7fb33f;
          padding-left: 0.15rem;
          padding-right: 0.15rem;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          border-radius: 4px;
        }
      }
    }
  }
  .cardWrapper:hover {
    background-color: #fafafa;
  }
}
@media all and (max-width: 500px) {
  .cardWrapper {
    border: none;
    width: 100%;
    height: 9rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    margin: 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;

    .cardContent {
      height: 5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      padding: 0.5rem;
      .descAndTitle {
        display: flex;
        flex-direction: column;
        .title {
          color: #1a181e;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        .title:hover {
          text-decoration: underline;
        }
        .shortDesc {
          text-transform: lowercase;
          margin-top: 8px;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }
      }
      .itemImage {
        width: 5rem;
        height: 5rem;
        border: solid 1px #e6e6e6;
        box-shadow: none;
        border-radius: 4px;
      }
    }
    .cardFooter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      height: 2rem;
      padding: 0.15rem;
      margin-top: 1rem;
      .buttonsContainer {
        display: flex;
        width: 35%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        .addsubsButton {
          display: flex;
          align-items: center;
          color: #7fb33f;
          height: 1.5rem !important;
          width: 2rem !important;
          border: none;
          margin: 0 0.5rem;
          justify-content: center;
        }
        .addsubsButton:hover {
          background-color: #e6e6e6;
        }
      }
      .pricingInformation {
        display: flex;
        flex-direction: row;
        align-items: center;
        .price {
          margin-right: 3px;
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
        }
        .strikedOutPrice {
          text-decoration: line-through;
          color: #858585;
          margin-right: 6px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        .percentageOffer {
          color: white;
          background-color: #7fb33f;
          padding-left: 0.15rem;
          padding-right: 0.15rem;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          border-radius: 4px;
        }
      }
    }
  }
  .cardWrapper:hover {
    background-color: #fafafa;
  }
}
