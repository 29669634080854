.paymentMethodFooter {
  width: 100%;
  bottom: 0;
  height: 4rem;
  background-color: white;
  position: sticky;
  border-top: 0.5px solid #e7e7e7;
  border-bottom: 0.5px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem !important;

  align-items: center;

  .paymentMethods {
    padding-left: 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .paymentImage {
      height: 2.5rem;
      width: 4.5rem;
      background-color: white;
    }
  }

  .securedTransaction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #4d4d4d;
    fill: #4d4d4d;
    padding-right: 4rem;

    .lockImg {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.25rem;
    }
  }
}

@media all and (max-width: 500px) {
  .paymentMethodFooter {
    width: 100%;
    height: 5rem;
    background-color: white;
    border-top: 0.5px solid #e7e7e7;
    border-bottom: 0.5px solid #e7e7e7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: inherit;

    .paymentMethods {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0;

      .paymentImage {
        height: 1.5rem;
        width: 3.5rem;
        background-color: white;
      }
    }

    .securedTransaction {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #4d4d4d;
      fill: #4d4d4d;
      margin-top: 0.5rem;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      padding: 0 !important;

      .lockImg {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.25rem;
      }
    }
  }
}
