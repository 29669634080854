.navBarWrapper {
  display: flex;
  flex-direction: row;
  background-color: white;
  position: sticky;
  border: none;
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid #e6e6e6;
  align-items: baseline;
  justify-content: flex-start;
  padding: 1rem 0 0 0;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
.dropdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 550;
  border-right: 2px solid #e6e6e6;
  padding-right: 0.5rem;
  cursor: pointer;
  padding-left: 8rem;
}
.navbarCategories {
  margin-left: 1rem;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
}
.categoryOnClick {
  color: #1f695d;
  border-bottom: solid 2px #1f695d;
  font-weight: 500;
}

nav a {
  font-size: 18px;
  color: black;
  text-decoration: none;
  font-weight: 500;
  margin-left: 35px;
  transition: 0.3s;
  line-height: 24px;
  padding: 0 0.5rem 0.5rem 0.5rem;
}
nav a:hover,
nav a.active {
  color: #1f695d;
  border-bottom: solid 3px #1f695d;
}

.dropdownBlock {
  position: absolute;
  padding: 0.5rem;
  margin-left: 8rem;
  display: flex;
  flex-direction: column;
  top: 100%;
  background: white;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  width: 18rem;
  max-height: 300px;
  overflow: auto;
  align-items: center;
}

.menuItem {
  width: 90%;
  height: 2.5rem;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
}
.menuItem:hover {
  text-decoration: none;
}

.numberofProduct {
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  color: white;
  background-color: #1f695d;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.navBarWrapperMobile {
  display: none;
}

@media all and (max-width: 500px) {
  .navBarWrapper {
    display: none;
  }
  .mobileNavBar {
    display: flex;
    height: 3rem;
    width: 3rem;
    background-color: #4d4d4d;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px rgb(0 0 0 / 14%),
      0 1px 18px rgb(0 0 0 / 12%);
    border-radius: 50%;
    justify-content: center;
    padding: 0.65rem;
    align-self: center;
  }
  .navBarWrapperMobile {
    width: 100%;
    display: flex;
    align-items: center;
    position: sticky;
    bottom: 4.5em;
    justify-content: center;
  }
  .modal-backdrop {
    opacity: 0.5 !important;
  }

  .modal {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .dropdownBlock {
    position: absolute;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
      0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 4px;
    width: 15rem;
    max-height: 300px;
    overflow: auto;
    align-items: center;
    margin: 0 !important;
    margin-top: 3rem !important;
    margin-right: 5rem !important;
    margin-left: -4.5rem !important;
  }
  .menuItem {
    width: 90%;
    height: 2.5rem;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
  }
  .menuItem:hover {
    text-decoration: none;
  }
  .centered-div {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    width: 100px;
    height: 100px;
  }
  .modal-content {
    border: none !important;
  }
}
