.menuWrapper {
  display: none;
}

@media all and (max-width: 500px) {
  .menuWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: sticky !important;
    bottom: 0 !important;
    background-color: white;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12);
    justify-content: space-around;
    height: 4rem;

    .activeClassNav {
      color: #206a5d;
      fill: #206a5d;

      svg {
        fill: #206a5d;
        color: #206a5d;
      }
    }

    .menuItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .topIcon {
        position: absolute;
        display: flex;
        align-items: center;
        color: white;
        background-color: #20695c;
        border-radius: 50%;
        height: 1rem;
        margin-left: -1.25rem;
        font-size: 13px;
        width: 1rem;
        justify-content: center;
        margin-top: -2.5rem;
      }
      .menuImage {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}
