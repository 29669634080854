.buttonWrapper {
  display: flex;
  align-items: center;
  padding: 0 2.05rem;
  color: #206a5d;
  border: 2px solid #206a5d;
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  height: 2rem;
}
.buttonWrapper:hover {
  background-color: #e4ebea;
}

@media all and (max-width: 500px) {
  .buttonWrapper {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.75rem;
    color: #206a5d;
    border: 1px solid #206a5d;
    background-color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    height: 1rem;
  }
  .buttonWrapper:hover {
    background-color: #e4ebea;
  }
}
