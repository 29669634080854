.cartWrapper {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  .emptyCartImg {
    height: 20rem;
    width: 20rem;
    margin-bottom: -2rem;
  }
  .buttonWrapper {
    width: 18rem;
  }
}

.pageWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  .mobileHeader {
    display: none;
  }

  .mobileCheckout {
    display: none;
  }

  .checkoutWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: white;

    .leftSection::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background-color: transparent;
    }

    .leftSection {
      width: 65%;
      height: 500px;
      align-items: end;
      margin-top: 1.5rem;
      overflow: auto;
      margin-left: 8rem;

      .billingInformation {
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 20px;
        line-height: 28px;
        padding: 1rem;
      }
      .itemCardWrapper {
        .mobileRemovebtn {
          display: none;
        }
        .itemCard {
          display: flex;
          flex-direction: row;
          width: 90%;
          margin-top: 1rem;
          border: solid 1px #e7e7e7;
          height: 10rem;
          padding: 1rem;
          border-radius: 4px;
          cursor: pointer;
          margin-left: 2rem;

          .itemImg {
            width: 7rem;
            height: 7.5rem;
          }

          .itemImgFrame {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 8rem;
            height: 8rem;
            border: solid 1px #e7e7e7;
            background-color: white;
          }

          .cardContent {
            display: flex;
            flex-direction: column;
            width: 80%;
            margin-left: 1rem;
            .cardBody {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              margin-top: 1rem;
              padding: 1rem;
              .buttonsContainer {
                display: flex;
                width: 25%;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                .addsubsButton {
                  display: flex;
                  align-items: center;
                  color: #7fb33f;
                  height: 1.5rem !important;
                  width: 2rem !important;
                  border: none;
                  margin: 0 0.5rem;
                  justify-content: center;
                }
                .addsubsButton:hover {
                  background-color: #e6e6e6;
                }
              }
              .itemPrice {
                font-size: 18px;
                line-height: 26px;
                margin-right: 2rem;
                display: flex;
                flex-direction: row;
                .strikedOut {
                  text-decoration: line-through;
                  color: #808080;
                  margin-left: 0.5rem;
                  font-size: 16px;
                  line-height: 24px;
                }
              }

              .qtyBox {
                border: 0.5px solid #e6e6e6;
                border-radius: 4px;
                width: 4rem;
                height: 1.5rem;
              }
            }

            .cardHeader {
              display: flex;
              width: 100%;
              justify-content: space-between;
              flex-direction: row;

              padding: 0 1rem;
              .itemName {
                font-size: 20px;
                line-height: 24px;
                cursor: pointer;
              }

              .itemName:hover {
                text-decoration: underline;
              }

              .removeBtn {
                font-size: 16px;
                line-height: 24px;
                color: #808080;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .rightSection {
      display: flex;
      flex-direction: column;
      width: 35%;
      margin-top: 1.5rem;
      align-items: flex-start;
      height: 500px;

      .checkoutBox {
        margin-top: 1rem;
        width: 80%;
        height: 20rem;
        border: solid 1px #e7e7e7;
        border-radius: 8px;
        display: flex;
        justify-content: center;

        .totalInfo {
          display: flex;
          padding: 1rem;
          width: 90%;
          flex-direction: column;
          margin-top: 0.25rem;

          .grandTotal {
            border-top: 1px dashed #e7e7e7;
            width: 100%;
            border-bottom: 1px dashed #e7e7e7;
            margin-top: 1rem;
            padding: 1rem 0;

            .chargeRow {
              font-size: 16px !important;
              line-height: 24px !important;
              font-weight: 500;
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-direction: row;
            }

            .taxesInfo {
              font-size: 14px;
              line-height: 24px;
              color: #808080;
            }
          }

          .chargeRow {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px !important;
            line-height: 1.42857143 !important;
            flex-direction: row;
          }

          .deliveryTime {
            margin-top: 1rem;
            font-size: 12px !important;
            line-height: 1.22857143 !important;
            color: #808080;
          }

          .buttonContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0;
            width: 100%;
            border-top: 1px dashed #e7e7e7;
            margin-top: 1rem;
            padding-top: 1rem;
          }
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .pageWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .mobileHeader {
      display: flex;
      flex-direction: row;
      position: sticky;
      position: -webkit-sticky;
      width: 100%;
      padding: 1rem;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid #e6e6e6;
      height: 4rem;
      top: 0;
      background-color: white;
      background: white;

      .stepInfo {
        font-size: 14px;
        line-height: 20px;
        color: #808080;
      }
    }

    .mobileCheckout {
      display: flex;
      width: 100%;
      bottom: 4em;
      position: sticky;
      flex-direction: row;
      padding: 0.75rem 0;
      justify-content: space-between;
      background-color: white;
      border-top: 0.5px solid #e7e7e7;

      border-top: 0.5px bottom #e7e7e7;
      height: 5rem;
      .mobileLeft {
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;
        justify-content: center;
        .price {
          font-size: 20px;
          line-height: 29px;
        }
        .viewPriceDetails {
          font-size: 12px;
          line-height: 20px;
          color: #206a5d;
          text-decoration: underline;
          font-weight: 500;
        }
      }
      .mobileRight {
        display: flex;
        width: 50%;
        align-items: center;
      }
    }

    .checkoutWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: white;
      align-items: center;

      .leftSection::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
        background-color: transparent;
      }

      .leftSection {
        width: 100%;
        height: fit-content;
        align-items: end;
        margin: 0 !important;
        margin-top: 1.5rem;
        overflow: auto;

        .billingInformation {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 14px;
          line-height: 20px;
          padding: 0.5rem;
          background-color: #f5f5f5;
          height: 3rem;
          align-items: center;
        }
        .itemCardWrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: solid 1px #e7e7e7;
          padding: 1rem 0.5rem 0.25rem 0.5rem;
          width: 90%;
          margin-left: 1rem;
          margin-top: 1rem;
          border-radius: 4px;
          .mobileRemovebtn {
            font-size: 14px;
            line-height: 20px;
            color: #808080;
            margin: 0 !important;
            display: flex;
            cursor: pointer;
            border-top: 0.5px #e6e6e6 solid;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 0.25rem 0;
          }
          .itemCard {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 0 !important;
            margin: 0 !important;
            height: 6.5rem;
            cursor: pointer;

            border: none;

            .itemImg {
              width: 5rem;
              height: 5.5rem;
            }

            .itemImgFrame {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 6rem;
              height: 6rem;
              border: solid 1px #e7e7e7;
              background-color: white;
            }

            .cardContent {
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-left: 0.5rem;

              .cardBody {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 1rem;
                width: 80%;

                .buttonsContainer {
                  display: flex;
                  width: 45%;
                  align-items: center;
                  justify-content: center;
                  flex-direction: row;
                  .addsubsButton {
                    display: flex;
                    align-items: center;
                    color: #7fb33f;
                    height: 1.5rem !important;
                    width: 2rem !important;
                    border: none;
                    margin: 0 0.5rem;
                    justify-content: center;
                  }
                  .addsubsButton:hover {
                    background-color: #e6e6e6;
                  }
                }
                .itemPrice {
                  font-size: 16px;
                  line-height: 26px;
                  margin-right: 2rem;
                  .strikedOut {
                    text-decoration: line-through;
                    color: #808080;
                    margin-left: 0.5rem;
                    font-size: 14px;
                    line-height: 24px;
                  }
                }

                .qtyBox {
                  border: 0.5px solid #e6e6e6;
                  border-radius: 4px;
                  width: 4rem;
                  height: 1.5rem;
                }
              }

              .cardHeader {
                display: flex;
                width: 80%;
                justify-content: space-between;
                flex-direction: row;

                .itemName {
                  font-size: 14px;
                  line-height: 20px;
                  cursor: pointer;
                }

                .itemName:hover {
                  text-decoration: underline;
                }

                .removeBtn {
                  display: none;
                }
              }
            }
          }
        }
      }

      .rightSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1.5rem;
        height: 260px;
        padding: 0.25rem;
      }
    }
  }
}
