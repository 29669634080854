.bannerBackground {
  background-color: #206a5d;
  color: #ffeaab;
  font-size: large;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  z-index: 555;
  height: 5vh;
  overflow: hidden;
  .leftHive {
    margin-top: 5rem;
    height: 10rem;
    margin-left: -2rem;
  }
  .rightHive {
    height: 10rem;
    margin-top: 2.15rem;
    margin-right: 1rem;
  }
  .offerText {
    a {
      color: #ffeaab;
    }
    margin-left: 2rem;
    margin-right: -1rem;
  }
}

@media all and (max-width: 500px) {
  .bannerBackground {
    font-size: 14px;
    height: 7vh;
    .leftHive,
    .rightHive {
      display: none; // Hide hives on mobile screens
    }

    .offerText {
      margin: auto; // Center the offer text for better alignment
      text-align: center;
      a {
        color: #ffeaab;
      }
    }
  }
}
