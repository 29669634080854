.swal-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.swal-custom-image {
  width: 60%;
  margin-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
}
.swal-custom-image-success {
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.swal-custom-text {
  font-size: 16px; /* Adjust font size as needed */
}
