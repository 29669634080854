.container {
  padding: 2rem;
  background-color: white;
  font-family: 'GalanoGrotesque', sans-serif;
  -webkit-font-smoothing: antialiased;
}

p {
  color: #4d4d4d;
}

.cursive-text {
  font-family: 'URW Chancery L', cursive;
}

