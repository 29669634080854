.categoriesWrapper {
  width: 90%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: white;
  flex-wrap: wrap;
  padding-left: 4rem;
  .categoryItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem;
    font-weight: 500;
    .imgBox {
      width: 10rem;
      height: 10rem;
      border: 1px solid #e7e7e7;
      border-radius: 8px;
    }
  }
}

@media all and (max-width: 500px) {
  .categoriesWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: white;
    height: fit-content;
    .categoryItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem !important;
      font-weight: 500;
      .imgBox {
        width: 5rem;
        height: 5rem;
        border: 1px solid #e7e7e7;
        border-radius: 8px;
      }
    }
  }
}
