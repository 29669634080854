$orangeL: #ff8303;
$orangeD: #ff7300;
$yellowL: #ffd764;
$yellowD: #ffa70b;
$greyL: #f0f0f0;
$black: #363636;
$greyD: #f0f0f0;
$whiteD: #f8f8ff;
$whiteL: #fff;
$bluebee: #7ec0ee;

.overlaySpinner {
  position: fixed !important;  //For Overlay to take the entire WINDOW // VERYYY IMPORTANT
  top: 0;
  width: 100% !important;
  height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  z-index: 9999 !important;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
}
.wavy-word {
  display: inline-block;
  opacity: 0;
  animation: slide-in 3s ease-out infinite; /* Infinite repetition */
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(0); /* Initial position */
  }
  50% {
    opacity: 1;
    transform: translateX(0); /* Final position */
  }
  100% {
    opacity: 0;
    transform: translateX(0); /* Exit to the right */
  }
}
