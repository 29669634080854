.cuponsBox {
  display: flex;
  margin-top: 2rem;
  width: 80%;
  height: 4rem;
  border: solid 1px #e7e7e7;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  .cuponInput {
    border: none;
    width: 90%;
    height: 3rem;
    background-color: white;
    margin-top: 0.25rem;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
    .invalid-feedback {
      display: none;
    }
    .feedback {
      margin-left: 1rem;
    }
  }
  cuponInput:focus {
    outline: none;
  }
  .cuponButton {
    border: none;
    width: 20%;
    height: 3rem;
    background-color: white;
    color: #206a5d;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn:hover,
  .cuponButton:hover {
    background-color: white;
    color: #206a5d;
  }
  .btn:active,
  .cuponButton:active {
    background-color: white;
    color: #206a5d;
  }
}

@media all and (max-width: 500px) {
  .cuponsBox {
    display: flex;
    margin-top: 1rem;
    width: 100%;
    height: 4rem;
    border: solid 1px #e7e7e7;
    border-radius: 8px;
    justify-content: space-between;
    padding: 0 0.5rem;
    .cuponInput {
      border: none;
      width: 85%;
      height: 2.5rem;
      background-color: white;
      margin-top: 0.25rem;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      .invalid-feedback {
        display: none;
      }
      .feedback {
        margin-left: 1rem;
      }
    }
    .cuponButton:focus,
    .cuponButton:active {
      outline: none !important;
      box-shadow: none;
      background-color: white;
    }
    .cuponButton {
      border: none;
      width: 20%;
      height: 3rem;
      background-color: white;
      color: #206a5d;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 1rem;
      padding-left: 1rem;
      margin-right: 1rem;
    }
    .btn:hover,
    .cuponButton:hover {
      background-color: white;
      color: #206a5d;
    }
  }
}
