.checkoutBox {
  margin-top: 1rem;
  width: 80%;
  height: fit-content;
  border: solid 1px #e7e7e7;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  .totalInfo {
    display: flex;
    padding: 1rem;
    width: 90%;
    flex-direction: column;
    margin-top: 0.25rem;

    .grandTotal {
      border-top: 1px dashed #e7e7e7;
      width: 100%;
      border-bottom: 1px dashed #e7e7e7;
      margin-top: 1rem;
      padding: 1rem 0;

      .chargeRow {
        font-size: 16px !important;
        line-height: 24px !important;
        font-weight: 500;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }

      .taxesInfo {
        font-size: 14px;
        line-height: 24px;
        color: #808080;
      }
    }

    .chargeRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 14px !important;
      line-height: 1.42857143 !important;
      flex-direction: row;
      .chargeTitle {
        display: flex;
        flex-direction: row;
      }
    }

    .deliveryTime {
      margin-top: 1rem;
      font-size: 12px !important;
      line-height: 1.22857143 !important;
      color: #808080;
    }

    .buttonContainer {
      display: flex;
      height: 5rem;
      align-items: center;
      justify-content: center;
      bottom: 0;
      width: 100%;
      border-top: 1px dashed #e7e7e7;
      margin-top: 1rem;
      padding-top: 1rem;
    }
  }
}

@media all and (max-width: 500px) {
  .checkoutBox {
    margin-top: 1rem;
    width: 100%;
    height: 13rem;
    border: solid 1px #e7e7e7;
    border-radius: 8px;
    display: flex;
    justify-content: center;

    .totalInfo {
      display: flex;
      padding: 0.5rem;
      width: 90%;
      flex-direction: column;
      margin-top: 0.25rem;

      .grandTotal {
        border-top: 1px dashed #e7e7e7;
        width: 100%;
        border-bottom: 1px dashed #e7e7e7;
        margin-top: 1rem;
        padding: 1rem 0;

        .chargeRow {
          font-size: 16px !important;
          line-height: 24px !important;
          font-weight: 500;
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          .freeDelivery {
            color: #3fcb3f;
          }
        }

        .taxesInfo {
          font-size: 14px;
          line-height: 24px;
          color: #808080;
        }
      }

      .chargeRow {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 14px !important;
        line-height: 1.42857143 !important;
        flex-direction: row;
      }

      .deliveryTime {
        margin-top: 1rem;
        font-size: 12px !important;
        line-height: 1.22857143 !important;
        color: #808080;
      }

      .buttonContainer {
        position: sticky;
        display: none;
        align-items: center;
        justify-content: center;
        bottom: 0;
        width: 100%;
        border-top: 1px dashed #e7e7e7;
        margin-top: 1rem;
        padding-top: 1rem;
      }
    }
  }
}
