.productPageWrapper {
  display: flex;
  flex-direction: row;

  .mobileHeader {
    display: none;
  }

  .mobileCheckout {
    display: none;
  }

  .leftSection {
    width: 40%;
    margin-top: 1rem;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .thumbnailImages {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 90%;
      justify-content: space-evenly;
      margin-top: 5rem;
      margin-bottom: 3rem;
      .activeThumbnail {
        box-shadow: 0px 0px 1px 1px #206a5d;
      }
      .thumbnail {
        height: 5rem;
        width: 5rem;
        cursor: pointer;
      }
    }
    .carouselDiv {
      width: 100%;
      .carouselImg {
        margin-left: 4rem;
        margin-top: 3rem;
        height: 30rem;
        width: 30rem;
      }
    }
    .backDiv {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 3.5rem;
      align-self: flex-start;
      margin-left: 5rem;
      padding: 1rem;
    }
    .carousel-dark {
      width: 100%;
      display: none;
      margin: 0 !important;
      .carousel-inner {
        width: 40%;
        .carousel-item .active-carousel-item {
          margin-left: 0 !important;
        }
      }
    }
  }
  .rightSection::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    background-color: transparent;
  }
  .rightSection {
    width: 50%;
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 90vh;
    overflow-y: scroll;
    margin-bottom: 2rem;
    .ratingsAndReviews {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      padding: 0 0.75rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
    }
    .descriptionBlock {
      width: 95%;
      height: 60vh;
      border-top: 1px solid #e6e6e6;
      margin-top: 1rem;
      .descriptiontitle {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-top: 1rem;
      }
      .productFeatures {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;

        li {
          list-style-type: none;
          margin-top: 1rem;
          text-wrap: wrap;
          text-align: justify;
          padding-right: 1rem;
        }
      }
    }

    .productTitle {
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
      padding: 1rem;
      .beeBackground {
        position: absolute;
        top: 20%;
        right: 3%;
        height: 23rem;
        width: 23rem;
        z-index: -5;
      }
    }
    .itemPrice {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      color: #206a5d;
      font-weight: bold;
      .strikedOut {
        text-decoration: line-through;
        color: #808080;
        margin-left: 0.5rem;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .variantSelect {
      padding: 1rem;
      display: flex;
      flex-direction: row;
      width: 100%;
      .isActive {
        box-shadow: 0px 0px 2px 2px #206a5d;
      }
      .variationBox {
        cursor: pointer;
        display: flex;
        height: 3rem;
        width: 6.5rem;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        margin-right: 1.5rem;
        justify-content: center;
        flex-direction: column;
        padding: 0.5rem;
        .quantity {
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
        }
        .price {
          font-size: 14px;
          line-height: 20px;
          color: #206a5d;
        }
      }
    }
    .buttonContainer {
      display: flex;
      flex-direction: row;
      height: 6rem;
      width: 100%;
      margin-top: 1rem;
      padding: 1rem;
      .buttonsContainer {
        display: flex;
        width: 35%;
        height: 3rem;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        .addsubsButton {
          display: flex;
          align-items: center;
          color: #7fb33f;
          height: 3rem !important;
          width: 3rem !important;
          border: none;
          margin: 0 0.5rem;
          justify-content: center;
        }
        .addsubsButton:hover {
          background-color: #e6e6e6;
        }
      }
      .primaryButton {
        color: white;
        padding: 0.5rem 2rem;
        background-color: #206a5d;
        align-items: center;
        box-shadow: none;
        border: none;
        border-radius: 8px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        width: 35%;
        height: 3rem;
        margin-right: 2rem;
      }
    }
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: black;
  }
}

@media all and (max-width: 500px) {
  .productPageWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .mobileHeader {
      display: flex;
      position: sticky;
      position: -webkit-sticky;
      width: 100%;
      align-items: center;
      padding: 1rem;
      justify-content: flex-start;
      align-items: center;
      height: 2.5rem;
      top: 0;
      background-color: white;
      background: white;
      z-index: 1000;
      .backDiv {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 0.25rem;
      }
    }

    .mobileCheckout {
      display: flex;
      width: 100%;
      bottom: 0;
      position: fixed !important;
      padding: 0.75rem 0;
      background-color: white;
      border-top: 0.5px solid #e7e7e7;
      border-top: 0.5px bottom #e7e7e7;
      height: 4rem;
      margin-top: 0 !important;

      .buttonContainer {
        display: flex;
        flex-direction: row;
        height: 4rem;
        width: 100%;
        justify-content: space-evenly;
        .buttonsContainer {
          display: flex;
          width: 35%;
          height: 3rem;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          .addsubsButton {
            display: flex;
            align-items: center;
            color: #7fb33f;
            height: 3rem !important;
            width: 3rem !important;
            border: none;
            margin: 0 0.5rem;
            justify-content: center;
          }
          .addsubsButton:hover {
            background-color: #e6e6e6;
          }
        }
        .primaryButton {
          color: white;
          padding: 0.5rem 1rem;
          background-color: #206a5d;
          align-items: center;
          box-shadow: none;
          border: none;
          border-radius: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          width: 45%;
          height: 3rem;
          margin: 0 !important;
        }
      }
    }
    .leftSection {
      width: 100%;
      margin-top: 0.5rem;
      align-items: center;
      display: flex;
      justify-content: center;
      .thumbnailImages {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-right: 0.5rem;
        justify-content: space-evenly;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        .activeThumbnail {
          box-shadow: 0px 0px 1px 1px #206a5d;
        }
        .thumbnail {
          height: 3rem;
          width: 3rem;
          cursor: pointer;
        }
      }
      .carouselDiv {
        width: 100% !important;
        margin: 0 !important;
        display: flex;
        align-items: center;
        .carouselImg {
          margin: auto !important;
          height: 20rem;
          width: 20rem;
        }
      }
      .backDiv {
        display: none;
      }

      .carousel {
        width: 100%;
        margin: 0 !important;
        .carousel-inner {
          .carousel-item {
            margin-left: 0 !important;
          }
        }
      }
    }
    .rightSection::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background-color: transparent;
    }
    .rightSection {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      height: 80vh;
      overflow-y: scroll;
      margin-bottom: 2rem;

      .descriptionBlock {
        width: 100%;
        height: 40vh;
        display: flex;
        flex-direction: column;
        .descriptiontitle {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          padding: 0 1rem;
        }
        .productFeatures {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;

          li {
            list-style-type: none;
            margin-top: 0 !important;
            text-wrap: wrap;
            text-align: justify;
            padding: 0.75rem;
            width: 100%;
          }
        }
      }

      .productTitle {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        padding: 0.75rem;
        text-align: justify;
        .beeBackground {
          position: absolute;
          top: 50;
          right: 10%;
          height: 15rem;
          width: 15rem;
          z-index: -5;
          display: none;
        }
      }
      .itemPrice {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        padding: 0 1rem;
      }
      .variantSelect {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .isActive {
          box-shadow: 0px 0px 2px 2px #206a5d;
        }
        .variationBox {
          cursor: pointer;
          display: flex;
          height: 2.5rem;
          width: 5.5rem;
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          justify-content: center;
          flex-direction: column;
          padding: 0.5rem;
          margin: 0 !important;
          .quantity {
            font-weight: 700;
            font-size: 10px;
            line-height: 14px;
          }
          .price {
            font-size: 12px;
            line-height: 18px;
            color: #206a5d;
          }
        }
      }
      .buttonContainer {
        display: none;
      }
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: black;
    }
  }
}
