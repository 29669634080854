.mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0 0 10rem;
  section {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly flex-start;
  }
  .sectionHeading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin: 1rem 0;

    .heading {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      margin-left: 2rem;
    }
    .sectionCount {
      height: 1.5rem;
      width: 2rem;
      color: white;
      background-color: #206a5d;
      border-radius: 8px;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media all and (max-width: 500px) {
  .mainWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 !important;
    section {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 !important;
    }
    .sectionHeading {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      width: 100%;
      margin: 1rem 0;

      .heading {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        margin-left: 2rem;
      }
      .sectionCount {
        height: 1.5rem;
        width: 2rem;
        color: white;
        background-color: #206a5d;
        border-radius: 8px;
        margin-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
