.buttonWrapper {
  color: white;
  padding: 1rem 1rem;
  background-color: #206a5d;
  align-items: center;
  box-shadow: none;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
